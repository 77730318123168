@import './variables';

// Bootstrap overrides

// spacing overrides
$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * 1,
  2: $spacer * 2,
  3: $spacer * 3,
  4: $spacer * 4,
  5: $spacer * 5,
  6: $spacer * 6,
  7: $spacer * 7,
  8: $spacer * 8,
  9: $spacer * 9,
  10: $spacer * 10,
  // special cases
  dot125: $spacer * 0.125,
  dot25: $spacer * 0.25,
  dot375: $spacer * 0.375,
  dot5: $spacer * 0.5,
  dot625: $spacer * 0.625,
  dot75: $spacer * 0.75,
  dot875: $spacer * 0.875,
  1dot125: $spacer * 1.125,
  1dot25: $spacer * 1.25,
  1dot375: $spacer * 1.375,
  1dot5: $spacer * 1.5,
  1dot75: $spacer * 1.75,
  1dot875: $spacer * 1.875,
  2dot25: $spacer * 2.25,
  2dot5: $spacer * 2.5,
  2dot75: $spacer * 2.75,
  3dot125: $spacer * 3.125,
  3dot75: $spacer * 3.75
);

$grid-gutter-width: 1.5rem;
$gutters: (
  0: 0,
  1: $spacer * 1,
  2: $spacer * 2,
  3: $spacer,
  4: $spacer * 3,
  5: $spacer * 4,
  // special cases
  dot625: $spacer * 0.625,
  1dot25: $spacer * 1.25
);

@import 'bootstrap/scss/bootstrap';

.dropdown-menu {
  border: 0.3px solid $dropdown-border-color;
  box-shadow: 0px 4px 8px $dropdown-box-shadow;
  overflow: hidden;
  border-radius: 0.5rem;
}

.dropdown-toggle {
  &.show {
    img {
      transform: rotate(180deg);
    }
    &.light {
      background-color: $dropdown-toggler-light-hover-color;
    }
    &.primary-btn {
      background-color: var(--primary-btn-active-background);
    }
  }
  &::after {
    display: none !important;
  }
}

.dropdown-item {
  padding: 0.625rem 1rem !important;
  &:hover {
    background-color: $dropdown-item-hover-background-color !important;
    color: $dropdown-item-hover-color !important;
  }
}

.pagination-dropdown {
  &.dropdown-menu {
    min-width: unset !important;
  }
}

.modal-footer > * {
  margin: 0 !important;
}

.dropdown-item {
  font-size: 1rem;
  text-align: center !important;
}

.dropdown-menu.show {
  display: flex !important;
}

table tbody td.numeric {
  font-family: sans-serif;
  font-size: 0.98rem;
}

.x-spreadsheet-menu .x-spreadsheet-icon {
  display: none;
}

.modal .x-spreadsheet-selector .x-spreadsheet-selector-area,
.modal .x-spreadsheet-selector .x-spreadsheet-selector-clipboard {
  border: 2px solid var(--medium-blue);
}

.modal .x-spreadsheet-selector .x-spreadsheet-selector-corner {
  background: var(--medium-blue);
}
